<!-- vue coding=utf-8 -->
<!--
 * @Author: fuhainan-
 * @Description: 头部注释
 * @Date: 2020-12-27 21:20:20
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-27 22:42:40
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/member/Infoauthentication.vue
-->
<template>
  <div class="user_content">
    <div v-if="isIdentify == 0 || againUp" class="noIsIdentify">
      <div class="noIsIdentifyTip">* 请拍摄或上传您的有效二代身份证</div>
      <div class="authen">
        <div class="noIsIdentifyTitle">拍摄/上传人像面</div>

        <div
          class="Certification Portrait"
          :style="{
            'background-image': frontPath
              ? 'url(' + frontPath + ')'
              : 'url(' + require('@/assets/images/Portrait.png') + ')',
          }"
          @click="showUpTipChange('Z')"
        >
          <div v-if="!frontPath" class="CertificationBtn">
            <img src="@/assets/images/CertificationBtn.png" alt="">
          </div>
        </div>

        <div class="noIsIdentifyTitle">拍摄/上传国徽面</div>

        <div
          class="Certification emblem"
          :style="{
            'background-image': backPath
              ? 'url(' + backPath + ')'
              : 'url(' + require('@/assets/images/emblem.png') + ')',
          }"
          @click="showUpTipChange('F')"
        >
          <div v-if="!backPath" class="CertificationBtn">
            <img src="@/assets/images/CertificationBtn.png" alt="">
          </div>
        </div>

        <div style="margin: 16px">
          <van-button class="btn ba7" @click="gotoIdentity">
            下一步,确认提交信息
          </van-button>
        </div>
      </div>

      <van-popup
        v-model="showUpTip"
        class="UpTipBox"
        round
        :style="{ width: '90%', height: '45%' }"
      >
        <div class="UpTipConten">
          <div class="UpTipTitle">身份证拍照范例</div>
          <div class="UpTipImg">
            <img src="@/assets/images/UpTipImg.png" alt="">
          </div>
          <div class="UpTipText">边框完整展示·字迹清晰·亮度均匀</div>

          <van-uploader
            ref="upfile"
            class="UpTipBtn"
            :before-read="beforeRead"
            :after-read="afterRead"
          >
            <!-- <div class="UpTipBtn" @click="goShoot">我知道了,立即拍摄</div> -->
            <!-- @click="goShoot" -->

            <div class="UpTipBtnInput">我知道了,立即拍摄</div>
          </van-uploader>
        </div>
      </van-popup>

      <van-popup
        v-model="upfailure"
        round
        :style="{ width: '90%', height: '25%' }"
        class="upfailureBox"
      >
        <div class="upfailure">
          <div class="upfailureTitle">
            {{ upfailureMsg }}
            <div
              v-if="upfailureData && upfailureData.length !== 0"
              class="upfailureReason"
            >
              <p v-for="(item, index) in upfailureData" :key="index">
                {{ item }}
              </p>
            </div>
          </div>

          <div class="upfailureBtns">
            <span class="upfailureCancel" @click="upfailure = false">取消</span>
            <span class="upfailureAgain" @click="AgainChange">重新上传</span>
          </div>
        </div>
      </van-popup>
    </div>
    <div v-if="isIdentify == 1 && !againUp" class="IsIdentify">
      <div class="authen">
        <p v-for="(text, index) in notice" :key="index" style="color: #666666">
          {{ text }}
        </p>
        <div class="authen_info">
          <ul>
            <li>
              <b>真实姓名</b><span>{{ realNameData.realName }}</span>
            </li>
            <li>
              <b>证件类型</b><span>{{ realNameData.cardType }}</span>
            </li>
            <li>
              <b>证件号码</b><span>{{ cutString(realNameData.identifier) }}</span>
            </li>
            <li>
              <b>开户行</b><span v-if="isBindCardState == 1">{{ cardData.bankName }}</span><span
                v-if="isBindCardState == 0"
                style="color: #e33f44"
                @click="gotoBindCard"
              >
                去绑定<van-icon name="arrow" />
              </span>
            </li>
            <li>
              <b>银行卡号</b><span v-if="isBindCardState == 1">{{
                cutString(cardData.bankCard)
              }}</span>
            </li>
          </ul>
        </div>
        <p class="tip">上传证件照片要求</p>
        <img class="tip2" src="@/assets/images/authentication.png" alt="">
        <div style="margin: 16px">
          <van-button v-if="resetNumber == 1" class="btn ba7" @click="checkid">
            重新认证
          </van-button>

          <van-button v-if="resetNumber == 0" class="btn ba9">
            您已认证
          </van-button>
        </div>
      </div>
    </div>

    <van-popup
      v-model="showBindCard"
      :overlay="false"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <div class="BindCardBox">
        <div class="BindCardTop">
          <span class="BindBack" @click="showBindCard = false">
            <van-icon name="arrow-left" size="25" />
          </span>
          <div class="BindTitle">绑定银行卡</div>
        </div>

        <van-form ref="form">
          <van-cell-group v-if="realNameData">
            <van-field
              v-model="realNameData.realName"
              label="真实姓名"
              readonly
              placeholder="请输入用户名"
            />
            <van-field
              v-model="setId"
              label="身份证号"
              readonly
              placeholder="请输入用户名"
            />
            <van-field
              v-model="cardNumber"
              :rules="rules.cardNumber"
              type="number"
              label="卡号"
              placeholder="请输银行卡号"
            />
            <van-field
              v-model="AccountBank"
              label="开户行支行"
              :rules="rules.AccountBank"
              placeholder="请输开户行支行"
            />
          </van-cell-group>
          <van-cell-group class="smsBox">
            <van-field
              v-model="phone"
              label="预留手机号"
              type="tel"
              :rules="rules.telRules"
              placeholder="请输入预留手机号"
            />

            <van-field
              v-model="sms"
              center
              clearable
              type="number"
              maxlength="6"
              label="短信验证码"
              :rules="rules.codeRules"
              placeholder="请输入短信验证码"
            >
              <template #button>
                <div v-if="!isCodeBtn" class="smsCodeBox" @click="vCodeBtn">
                  {{ innerText }}
                </div>

                <div v-else class="smsCodeBox">
                  {{ innerText }}
                </div>

                <!-- <van-button size="small" type="primary">发送验证码</van-button> -->
              </template>
            </van-field>
          </van-cell-group>
          <div class="BindBackBtns">
            <div class="BindBackText">
              为了资金安全，请绑定持卡人本人的银行卡
            </div>
            <div class="BindBackBtn" @click="bindBankCard">确认绑定</div>
          </div>
        </van-form>
      </div>
    </van-popup>

    <div id="c1" ref="c1" />
    <!-- <div class="brew_box" v-if="isDingxiang"></div> -->
  </div>
</template>

<script>
// import axios from "axios"; // 引入axios
// import qs from 'qs';
import Vue from 'vue'
import { Button, CellGroup, Field, Form, Icon, Popup, Toast, Uploader } from 'vant'

Vue.use(Toast)
  .use(Icon)
  .use(Button)
  .use(Field)
  .use(Uploader)
  .use(Popup)
  .use(CellGroup)
  .use(Form)

import {
  getRealNameInfo,
  getUploadImg,
  identityAuthentication,
  againRealName,
  authnewSend,
  bindBankCard
} from '@/services/userApi'
import { cutString, IDcutString } from '@/utils/public'

export default {
  data() {
    return {
      rules: {
        // 手机号验证
        telRules: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'onBlur'
          },
          {
            // 自定义校验规则
            validator: (value) => {
              return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
                value
              )
            },
            message: '请输入正确格式的手机号码',
            trigger: 'onBlur'
          }
        ],
        // 银行卡号验证
        cardNumber: [
          {
            required: true,
            message: '银行卡号不能为空',
            trigger: 'onBlur'
          },
          {
            // 自定义校验规则
            validator: (value) => {
              return /^([1-9]{1})(\d{15}|\d{18})$/.test(value)
            },
            message: '请输入正确格式的银行卡号',
            trigger: 'onBlur'
          }
        ],
        // 短信验证校验
        codeRules: [
          {
            required: true,
            message: '手机验证码不能为空',
            trigger: 'onBlur'
          },
          {
            // 自定义校验规则
            validator: (value) => {
              if (value.length !== 6) {
                return false
              } else {
                return true
              }
            },
            message: '请输入正确格式的验证码',
            trigger: 'onBlur'
          }
        ],
        AccountBank: [
          {
            required: true,
            message: '开户行支行不能为空',
            trigger: 'onBlur'
          }
        ]
      },

      errorText: '',
      phone: '',
      sms: '',
      cardNumber: '',
      AccountBank: '',
      value: '',
      againUp: false,
      upfailure: false,
      upfailureMsg: '',
      upfailureData: null,
      IDType: '',
      showUpTip: false,
      idCard: '',
      isIdentify: '',
      isBindCardState: '',
      intest: '去认证',
      realNameData: null,
      cardData: null,
      notice: null,
      resetNumber: 0,
      fileList: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      frontPath: '',
      backPath: '',
      showBindCard: false,
      areaCode: '86', // 区域码
      // 获取验证码
      isDingxiang: false, // 顶象模拟蒙层
      isCodeBtn: false,
      wait: 59, // 倒计时
      innerText: '获取验证码',
      myCaptcha: '', // 顶象验证
      myCaptchaToken: '', // 滑块token
      codeNumber: '0' // getinfo
    }
  },
  computed: {
    setId() {
      if (this.realNameData) {
        return IDcutString(this.realNameData.identifier)
      }
      return null
    }
  },
  created() {
    this.getinfo()
  },
  mounted() {
    const _self = this
    // 实例化顶象无感验证

    // eslint-disable-next-line no-undef
    _self.myCaptcha = _dx.Captcha(document.getElementById('c1'), {
      appId: '6738137ae0a10824eb3b5f775a1bf7fb',

      success: function(token) {
        _self.myCaptchaToken = token
        _self.lsendNewByWeChat()
        _self.myCaptcha.hide()
        _self.isDingxiang = false
      },
      fail: function(err) {
        console.log(err)
      },
      language: localStorage.getItem('language'),
      style: 'popup',
      inlineFloatPosition: 'up'
    })
  },
  methods: {
    // 获取验证码
    vCodeBtn() {
      if (this.phone.length <= 0) {
        Toast('请输入预留手机号')
        return
      }

      const _phone = this.phone
      const phoneReg = /^1\d{10}$/

      if (!_phone || !phoneReg.test(_phone)) {
        Toast('请输入有效的手机号码！')
        return false
      }

      this.wait = 59

      this.myCaptcha.show()

      this.isDingxiang = true
    },
    // 获取验证码请求
    lsendNewByWeChat() {
      const _self = this
      const params = {
        mobile: _self.phone
        // invitationCode: '',
        // areaCode: _self.areaCode,
        // myCaptchaToken: _self.myCaptchaToken,
      }

      authnewSend(params).then((res) => {
        if (Number(res.code) === 200) {
          _self.sTime()
        }
      })

      this.sTime()
    },
    // 验证码倒计时
    sTime() {
      const _self = this
      if (_self.wait === 0) {
        _self.innerText = '获取验证码'
        _self.myCaptchaToken = ''
        _self.myCaptcha.reload()
        _self.wait = 59
        _self.isCodeBtn = false
      } else {
        _self.innerText = _self.wait + 's后再发'
        _self.isCodeBtn = true
        _self.wait--
        setTimeout(function() {
          _self.sTime()
        }, 1000)
      }
    },
    // 绑定银行卡
    bindBankCard(values) {
      const _that = this
      this.$refs.form
        .validate()
        .then(() => {
          const body = {
            bankCard: this.cardNumber,
            phone: this.phone,
            bankName: this.AccountBank,
            code: this.sms
          }

          bindBankCard(body).then(res => {
            if (Number(res.code) === 200) {
              Toast('银行卡绑定成功！')
              setTimeout(() => {
                _that.showBindCard = false
                _that.getinfo()
              }, 500)
            }
          })
        })
    },

    // 截取身份后四位
    cutString(str) {
      return cutString(str, 4)
    },
    // 弹出规范
    showUpTipChange(type) {
      this.showUpTip = true
      this.IDType = type
    },
    // 调取上传文件选择框
    goShoot() {
      this.$nextTick(() => {
        this.showUpTip = false
        this.$refs.upfile.chooseFile()
      })
    },

    // 文件读取前的回调函数
    beforeRead(file) {
      this.showUpTip = false
      return true
    },
    // 文件读取完成后的回调函数
    afterRead(file) {
      this.showUpTip = false
      const body = {
        file: file.content
      }

      getUploadImg(body).then((res) => {
        if (Number(res.code) === 200) {
          if (this.IDType === 'Z') {
            this.frontPath = res.url
          } else if (this.IDType === 'F') {
            this.backPath = res.url
          }
        }
      })
    },
    // 确认绑定
    gotoIdentity() {
      const _that = this
      if (this.frontPath.length <= 0) {
        Toast('请上传身份证人像面')
        return
      }
      if (this.backPath.length <= 0) {
        Toast('请上传身份证国徽面')
        return
      }
      const data = {
        frontPath: this.frontPath,
        backPath: this.backPath
      }
      if (this.againUp) {
        againRealName(data).then((res) => {
          if (Number(res.code) === 200) {
            Toast.success(res.msg)

            setTimeout(() => {
              _that.againUp = false
              _that.getinfo()
            }, 500)
          } else if (Number(res.code) === 400) {
            this.upfailure = true
            this.upfailureMsg = res.msg
            this.upfailureData = res.data
          }
          console.log('重新认证', res)
        })
      } else {
        identityAuthentication(data).then((res) => {
          if (Number(res.code) === 200) {
            Toast.success(res.msg)
            setTimeout(() => {
              _that.getinfo()
            }, 500)
          } else if (Number(res.code) === 400) {
            this.upfailure = true
            this.upfailureMsg = res.msg
            this.upfailureData = res.data
          }
        })
      }
    },
    // 重新上传按钮
    AgainChange() {
      this.upfailure = false
      this.frontPath = ''
      this.backPath = ''
    },
    // 绑定银行卡
    gotoBindCard() {
      this.showBindCard = true
    },
    getinfo() {
      getRealNameInfo().then((res) => {
        if (Number(res.code) === 200) {
          this.isIdentify = res.data.isRealNameState
          this.isBindCardState = res.data.isBindCardState
          this.notice = res.data.notice
          this.resetNumber = res.data.resetNumber

          if (this.isIdentify === 0) {
            this.intest = '去认证'
          } else if (this.isIdentify === 1) {
            this.intest = '您已认证'

            this.realNameData = res.data.realNameData
            this.cardData = res.data.cardData
          } else if (this.isIdentify === 2) {
            Toast('提交已上限')
          }
        }
      })
    },
    // 重新认证
    checkid() {
      this.againUp = true

      // let parm = {
      //   idCard: this.idCard,
      // };
      // againRealName(parm).then((res) => {
      //   if (Number(res.code) === 200) {
      //     window.location.href = res.data.identifyUrl;
      //   }
      // });
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  height: 100%;
  background: #fff;
  overflow: hidden;
}

.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  background: #fff;
  padding-bottom: 80px;

  .noIsIdentify {
    width: 100%;

    .noIsIdentifyTip {
      width: 100%;
      height: 33px;
      background: #f9e1dd;
      color: #e33f44;
      padding: 0 10px;
      line-height: 33px;
      text-align: left;
      font-size: 14px;
    }

    .authen {
      .noIsIdentifyTitle {
        font-size: 15px;
        font-weight: 400;
        color: #000000;
        //   background-image: url("/images/Portrait.png");
      }

      .Certification {
        width: 332px;
        height: 161px;
        // background: #000;
        margin: 20px auto;
        border: 1px solid #f2f2f2;
        border-radius: 10px;
        position: relative;

        .CertificationBtn {
          width: 61px;
          height: 61px;
          // background: #000;
          position: absolute;
          top: 25%;
          left: 40%;

          img {
            width: 61px;
            height: 61px;
          }
        }

        //  background: url("/images/Portrait.png") no-repeat;
      }

      .Portrait {
        background-size: 332px 161px;

        // background-image: url("../../../assets/images/Portrait.png");
      }

      .emblem {
        width: 332px;
        height: 161px;
        background-size: 332px 161px;

        background-image: url("../../../assets/images/emblem.png");
      }
    }
  }

  .IsIdentify {
    .authen {
      & > p {
        color: #666666;
        text-align: left;
        margin-top: 10px;
        font-size: 13px;
        line-height: 23px;
      }

      .authen_inp {
        padding: 10px;
      }

      .authen_info {
        background: #fff;
        padding: 10px;
        border-radius: 10px;

        & > ul li {
          padding: 10px 0;
          border-bottom: 1px solid #f2f2f2;
          display: flex;
          justify-content: space-between;

          & > span:nth-child(2) {
            color: #999999;
          }
        }
      }

      .tip {
        color: #e33f44;
        font-size: 14px;
      }

      .tip2 {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  .authen {
    margin: 13px;

    .btn {
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      color: #fff;
    }

    .ba9 {
      background: #999999;
    }

    .ba7 {
      background: #e33f44;
    }
  }
}

.van-icon {
  vertical-align: middle;
}

.UpTipBox {
  padding: 20px;

  .UpTipConten {
    .UpTipTitle {
      font-size: 15px;
      font-weight: 400;
    }

    .UpTipText {
      text-align: center;
      font-size: 14px;
      color: #999999;
      margin: 10px 0;
    }

    .UpTipBtn {
      width: 100%;
      height: 40px;
      color: #fff;
      line-height: 40px;
      text-align: center;
      background: linear-gradient(0deg, #f73037 0%, #ff5654 100%);
      border-radius: 7px;
      display: inline-block;
    }
  }
}

.UpTipBtn {
  /deep/ .van-uploader__input-wrapper {
    display: block;
    width: 100%;

    /deep/ .van-uploader__input {
      width: 100% !important;
      display: block;
    }
  }
}

.upfailureBox {
  padding: 20px;

  .upfailure {
    position: relative;
    height: 100%;

    .upfailureTitle {
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
    }

    .upfailureBtns {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      span {
        width: 40%;
        margin: 3%;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        border: 1px solid #e33f44;
        border-radius: 20px;
      }

      .upfailureCancel {
        color: #e33f44;
      }

      .upfailureAgain {
        color: #fff;
        background: #e33f44;
      }
    }
  }
}

.BindCardBox {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  position: relative;

  .BindCardTop {
    text-align: left;
    width: 100%;
    height: 50px;
    position: relative;
    padding: 5px 0;
    background: #fff;
    border-bottom: 1px solid #f1f1f1;

    .BindBack {
      width: 30px;
      height: 30px;
      display: inline-block;
      position: relative;
      z-index: 501;
    }

    .BindTitle {
      padding: 5px 0;
      z-index: 499;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }

  .BindBackBtns {
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 120px;
    left: 0;
    right: 0;
    //  background: #000;
    .BindBackText {
      text-align: center;
      color: #e33f44;
      font-size: 13px;
      margin: 10px 0;
      font-weight: 400;
    }

    .BindBackBtn {
      width: 80%;
      height: 43px;
      background: #f7263c;
      border-radius: 7px;
      margin: 20px auto;
      color: #fff;
      line-height: 43px;
    }
  }
}

.smsBox {
  margin-top: 10px;
}

.smsCodeBox {
  color: #e33f44;
  border: 1px solid #e33f44;
  border-radius: 7px;
  font-size: 12px;
  padding: 2px 7px;
}

.brew_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  opacity: 0.5;
  z-index: 2002;
}

.dx_captcha_basic-style-popup {
  z-index: 2003;
}
</style>
